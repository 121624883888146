
import React, { useState, useEffect } from 'react';
import './App.css';
import 'bulma/css/bulma.min.css';
import emailjs from '@emailjs/browser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser, faPhone, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// 75 142 250
// 28 43 69

const App = () => {


  return (
    <div className='main'>
      <Navigation />
      <section id="profile" className='section gradient' /> {/* whitespace */}
      <section className='hero is-halfheight '>
        <div class="hero-body gradient">
          <div class="container has-text-centered">
            <h1 className='title is-size-4-mobile has-text-white mb-0'>Hey there! </h1>
            <h1 className='title is-size-4-mobile has-text-white'>My name is Magnus Frisk</h1>
            <h2 className='subtitle mb-6 has-text-white'>I'm an IT Product Developer located in Aarhus, Denmark</h2>
            <h2 className='subtitle has-text-white'>Have a look around :)</h2>
          </div>

        </div>
      </section>
      <section className='section gradient' /> {/* whitespace */}
      <Projects />
      <Resume />
      <Contact />
      <section className='section' /> {/* whitespace */}
    </div>

  );
};





function Contact() {
  let formCleanObject = {
    from_name: "",
    from_email: "",
    from_phone: "",
    message: ""
  }
  const [emailSent, setEmailSent] = useState(false);
  const [formData, setFormData] = useState(formCleanObject);

  const sendEmail = (e) => {
    let isContactInfo = formData.from_email !== "" && formData.from_phone !== "";
    if (!isContactInfo) {
      return;
    }
    let isMessage = formData.message !== "";
    if (!isMessage) {
      return;
    }
    let isName = formData.from_name !== "";
    if (!isName) {
      return;
    }
    e.preventDefault();
    emailjs.send('service_kqby6tq', 'template_nj3z52w', formData, 'A1X7WD4hpp1711RPA')
      .then((result) => {
        console.log(result.text);
        setFormData(formCleanObject)
        setEmailSent(true);
        setTimeout(() => {
          setEmailSent(false);
        }, 3000);

      }, (error) => {
        console.log(error.text);
      });
  };
  return (
    <section id="contact" className='section'>
      <h1 className='title is-2 has-text-centered has-text-info'>Contact</h1>
      <div className='container has-text-centered'>

        <span className='icon is-large mb-2'>
          <a target='_blank' href='https://www.linkedin.com/in/frisk1'><FontAwesomeIcon icon={faLinkedin} size='3x' ></FontAwesomeIcon></a>
        </span>
        <h2 className='subtitle pb-5'>Contact me on Linkedin or use the contact form below</h2>
      </div>
      <div className='container'>
        <form onSubmit={sendEmail} className='has-addons has-addons-centered'>
          <div className='field-body field'>
            <div class="field">
              <label className='label'>Name</label>
              <p class="control has-icons-left has-icons-right">
                <input className="input" name="from_name" type="text" placeholder="Name"
                  onChange={e => setFormData({ ...formData, from_name: e.target.value })} value={formData.from_name || ""} />
                <span class="icon is-small is-left">
                  <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                </span>
              </p>
              {
                formData.from_name === "" ? <p class="help is-info">Name is required.</p> : <></>
              }
            </div>
            <div class="field">
              <label className='label'>Email</label>
              <p class="control has-icons-left has-icons-right">
                <input class="input" type="email" name="from_email" placeholder="Email"
                  onChange={e => setFormData({ ...formData, from_email: e.target.value })} value={formData.from_email || ""} />
                <span class="icon is-small is-left">
                  <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                </span>
              </p>
              {
                (formData.from_phone === "" && formData.from_email === "") ? <p class="help is-info">Email address or phone number is required.</p> : <></>
              }
            </div>
            <div class="field">
              <label className='label'>Phone Number</label>
              <p class="control has-icons-left has-icons-right">
                <input className="input" name="from_phone" type="text" placeholder="Phone Number"
                  onChange={e => setFormData({ ...formData, from_phone: e.target.value })} value={formData.from_phone || ""} />
                <span class="icon is-small is-left">
                  <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                </span>
              </p>

            </div>


          </div>
          <div class="field">
            <label class="label">Message</label>
            <div class="control">
              <textarea class="textarea" name="message" placeholder="Message"
                onChange={e => setFormData({ ...formData, message: e.target.value })} value={formData.message || ""}></textarea>
            </div>
            {
              formData.message === "" ? <p class="help is-info">Message is required.</p> : <></>
            }
          </div>

          <div class="field has-text-right">
            <input type="submit" value="Send" className='button is-primary' />
          </div>
        </form>
      </div >
      {
        emailSent ? <EmailModal /> : <></>
      }
    </section>
  );
}



function EmailModal() {
  return (
    <div class="modal is-active ">
      <div class="modal-background"></div>

      <div class="modal-content">
        <div class="box has-background-info has-text-centered">
          <p className='title is-4 has-text-white'>Message sent succesfully!</p>

        </div>
      </div>

      <button class="modal-close is-large" aria-label="close"></button>
    </div>
  );
}

function Projects() {
  return (
    <section id="projects" className='section pb-0'>
      <div className='container'>
        <h1 className='title is-2 has-text-centered has-text-info pb-5'>Projects</h1>

        <div className='columns'>
          <Project title="ChromaNails"
            text="My Bachelor Thesis was titled ChromaNails: Re-Programmable Multi-Colored
            High-Resolution On-Body Interfaces using Photochromic Nail Polish. We created
            a re-programmable nail polish and a re-programming device that allows us to
            create on-body interfaces. We also proposed an alteration to the algorithm to extend the
            color gamut of photochromic CMY solutions."
            images={['./images/chromanails/Front-side-prototype.jpg', './images/chromanails/BestDemo.jpg',
              './images/chromanails/QR.jpg',
              './images/chromanails/nail.jpg', './images/chromanails/DAE_DYES.jpg',
              './images/chromanails/reprogram_process.jpg'
            ]}
            video="https://youtu.be/ZLpE6O6ghgA"
            project="BEST DEMO AWARD - UIST 2023"
            paper="https://dl.acm.org/doi/abs/10.1145/3586182.3615824?casa_token=xPL35DNdEpsAAAAA:sLphwVd3QWW0O_eq1mP8grgUI8jxobAP87LNBrAKrnKDa6TovnJ_iJiTdLTVKvqt9nwjt31d_avyfw"
            subIcon={faTrophy}
          />
          <Project title="MyOceanEye"
            text="In the course Innovation Project, we made a DIY floating camera, that live-streams
            the video feed to our webapp, for the user to see. The webapp has a YOLO Visual Recognition model,
            that analyses the live-stream and tells the user if fish etc. has been spotted and captures screenshots.
            These are then added to the users personal page, where the user can help verify images to generate a larger
            dataset for AI training."
            images={['./images/inno/forside.jpg', './images/inno/evaluation.jpg', './images/inno/AI.jpg',
              './images/inno/kig.jpg']}
            video="https://www.youtube.com/watch?v=wteYq2ID9ko"
            project="Presented at Milan Design Week 2025"
            subIcon={faTrophy}
          />
        </div>

        <div className='columns'>
          <Project title="Self-Supervised Deep Learning"
            text="In the course Deep Learning for Visual Recognition, we made a Deep Learning
            model that uses pretext tasks in a self-supervised manner, to train itself within a domain.
            The model is then trained using transfer learning to the original dataset.
            The purpose of the project is to showcase that self-supervised models are more general
            and better at solving visual tasks when the dataset is large, but the labels are sparse."
            images={['./images/deep/first_img_scaled.jpg', './images/deep/PretextTasks.png',
              './images/deep/NetworkarchV2.png', './images/deep/100epochs.png',
              './images/deep/transfer_our_confusion.png'
            ]}
            project="School Project"
            report="./reports/Deep_Learning.pdf"
          />
          <Project title="Monitoring PROTOlab using IoT"
            text="In the course Building the Internet of Things using Peer to Peer and Cloud Computing,
            we made a monitoring system for the PROTOlab room at our school.
            The system was capable of monitoring 3D printers, Laser Cutters  and human presence in real-time,
            as the room is usually occupied and thus the website gave an overview of the available machines.
            The website is not receiving data, but you can try it out on the link."
            images={['./images/P2P/startpage.png', './images/P2P/architecture.jpg',
              './images/P2P/zones.png'
            ]}
            video="https://youtu.be/sLIjEPATWGY"
            project="School Project"
            website="https://auprotolab.netlify.app/"
          />
        </div>

        <div className='columns'>
          <Project title="Build A Beat"
            text="In the course Physical Computing, we made a physical toy to be used by children.
            This toy makes learning about music fun and easy for kids by helping them understand different sounds and rhythms."
            images={['./images/physcom/real.png', './images/physcom/SideCropped.png',
              './images/physcom/TopCropped.png', './images/physcom/ExplodedCropped.jpg',
              './images/physcom/firsthalf.jpg', './images/physcom/secondhalf.jpg'
            ]}
            video="https://www.youtube.com/watch?v=tzGNbr_DZTU"
            project="School Project"
          />
          <Project title="RunningMan"
            text="In the course IT Product Development Project, we made a physical prototype to be used in the high-school course: Informatics.
              The prototype is a teaching device that helps users understand central concepts from database systems."
            images={['./images/itpdp/forside.jpg', './images/itpdp/blockfirst.png',
              './images/itpdp/Blocks.jpg', './images/itpdp/execution.jpg',
              './images/itpdp/Skaerm1.png', './images/itpdp/interaktion.jpg',
              './images/itpdp/renderex.jpg', './images/itpdp/query.png',
              './images/itpdp/model2.png'
            ]}
            video="https://www.youtube.com/watch?v=VwuGQRwCbDk"
            project="School Project"
          />
        </div>

        <div className='columns'>
          <Project title="Communigaming"
            text="In the course Designing Wearables, we made a wearable communication device for gaming.
            Most gaming wearables focus on proactively communication and often as a respone to something in-game, e.g. giving a high-five after killing a boss.
            Instead, we focused on passive and reactive communication in which peers can communicate without doing anything.
            This can be feeling your peers' pulse or their muscle contractions and then you can make your own decisions based on the feedback."
            images={['./images/wearables/devices.png', './images/wearables/arm.jpg', './images/wearables/leg.jpg',
              './images/wearables/InterfaceTop.png', './images/wearables/InterfaceBottom.png', './images/wearables/components.jpg']}
            project="School Project"
            report="./reports/Communigaming.pdf"
          />

          <Project title="Replica Flower Pot"
            text="I was challenged to replicate the touch mobile Flower Pot through 3D print and C++.
          The lamp is based on an ATmega328p and uses capacitive sensing and LED strip as light source. It is also battery powered."
            images={['./images/lampe/render.png', './images/lampe/lampeclose.jpg', './images/lampe/lampe2.jpg']}
            video='https://youtube.com/shorts/ylp42lcAYsg?feature=share'
            project="Hobby Project"
          />
        </div>

        <div className='columns'>
          <Project title="HotCiv"
            text="In the course Software Engineering and Architecture, we developed a simple HotCiv game inspired by Civilizations.
            It is build upon TDD and design patterns and is compositional, making it configurable for new versions."
            images={['./images/swea/hotciv.png', './images/swea/configuration.png',
              './images/swea/testing.png', './images/swea/state.png'
            ]}
            project="School Project"
          />
          <Project title="RETHINKING ENERGY CONSUMPTION"
            text="In the course Social and Aethestic Interaction Design, we used critical and
            speculative design to come up with an alternative energy consumption scheme. Our project focused
            mostly on maintaining habits and see how people react in new environments."
            images={['./images/SAINT/phone.jpg', './images/SAINT/balance.jpg',
              './images/SAINT/health_score.png', './images/SAINT/expo.jpg',
              './images/SAINT/Balloon.png', './images/SAINT/SocialStatus.png',
              './images/SAINT/Buddy.png', './images/SAINT/Interview.jpg',

            ]}
            project="School Project"
            report="./reports/SAINT.pdf"
          />
        </div>

        <div className='columns'>
          <Project title="Venue Calender"
            text="In the course Experimental Systems Design, we made a Participatory Design with the venue Radar.
          We did field studies, future workshops and evaluations to understand their ways of work and their IT limitations and solved it with a UI."
            images={['./images/exsys/Landing.png', './images/exsys/Artistform.png',
              './images/exsys/CreateEvent.jpg', './images/exsys/Roles.jpg',
              './images/exsys/Timeline.png', './images/exsys/CreateUser.jpg',
              './images/exsys/Event.jpg', './images/exsys/Artistform2.jpg',
              './images/exsys/CreateEvent2.jpg', './images/exsys/Event2.jpg']}
            project="School Project"
          />
          <Project title="SPIKE"
            text="In the course Shape Changing Objects and Spaces, we made a social robot capable
            of hosting a socratic dialogue regarding the environment. The robot is an art installation
            siutated in the ceiling of a shipping container. The ceiling moves to illustrate conversation.
            It uses the Scott Russel Mechanism to minimize space.
            The robot uses F-formations to change the flow
            of the conversation and engage the users."
            images={['./images/SOS-spike/render.jpg', './images/SOS-spike/PepzWidDaRendz.jpg',
              './images/SOS-spike/prot1.jpg', './images/SOS-spike/prot2.jpg',
              './images/SOS-spike/material.jpg', './images/SOS-spike/scott.jpg'
            ]}
            project="School Project"
            report="./reports/SOS.pdf"
          />
        </div>

        <div className='columns'>
          <Project title="Espresso Machine Redesign"
            text="In the course Design as Products, Services, Systems and Experiences, we redesigned an IT product of choice in different design periods.
            To accomplish this, we sketched, idea generated and modelled different designs to incorprate the styles and frameworks."
            images={['./images/design/forside.png', './images/design/arts.png', './images/design/bauhaus.png',
              './images/design/divider.png', './images/design/streamline.png', './images/design/ulm.png',
              './images/design/pomo.png']}
            project="School Project"
          />
          <Project title="Dart Counter"
            text="I made this project with a friend for his dad, as they play a lot of dart at home.
          The prototype runs on an ESP32, which I made 2 buses for the screens. It has different settings and a buzzer for sound."
            images={['./images/dart/dart.jpg']}
            project="Hobby Project"
          />
        </div>

        <div className='columns'>
          <Project title="Beer Darts"
            text="I discovered a drinking game called Beer Dart, but I had no way of transporting darts safely.
          Furthermore, I had issues with the beer can getting knocked over. So FTIJPI, here is a magnetic case that clicks a beer on and solves both issues."
            images={['./images/beerdart/render.png', './images/beerdart/beer.jpg', './images/beerdart/unclick.jpg',
              './images/beerdart/click.jpg', './images/beerdart/together.jpg']}
            project="Hobby Project"
          />
        </div>
      </div>

    </section>
  );
}

function Resume() {
  return (
    <section id="resume" className='section pb-0'>
      <div className='container'>
        <h1 className='title is-2 has-text-centered has-text-info'>Resume</h1>
        <div className='columns'>
          <div className='column'>
            <h2 className='title has-text-centered mb-6 has-text-info'>Experience</h2>
            <ResumeItem title="Student Sofware Developer"
              firm="Visma - XFlow/EduAdm"
              period="February 2024 - "
              text="I am working part-time on the EduAdm project in Visma.
              I work on full stack tasks in MVC .NET"
            />
            <ResumeItem title="Teaching Assistant - Engineering Interactive Technologies (EIT)"
              firm="Aarhus University"
              period="September 2023 to December 2023"
              text="In the EIT course, the students learn how to engineer interactive prototypes.
              This includes using various technologies and interactive materials. The students
              fabricate an functional touchpad, electroluminescent displays, printable sensors and more.
              They also create their own project in teams, which I helped supervise."
            />
            <ResumeItem title="Teaching Assistant - IT Product Development Project (ITPDP)"
              firm="Aarhus University"
              period="February 2022 to June 2022 and February 2023 to June 2023"
              text="In the ITPDP course, the students learn how to do research within a target group and
                in collaboration create a physical IT product to help solve a problem. The product needs to have a
                database connected and a cloud function. My job is to guide them in their design process."
            />
            <ResumeItem title="Teaching Assistant - Software Engineering and Architecture (SWEA)"
              firm="Aarhus University"
              period="September 2022 to December 2022"
              text="In the SWEA course, the students learn advanced programming techniques to create
                large-scale flexible and reliable software using TDD, design patterns and clean code principles.
                The students implement a game called HotStone, which is a card game inspired by HeartStone. My job was
                to guide them in their choices and see pros and cons of the different choices.
                I also spent a lot of them analyzing and assesing their code."
            />
            <ResumeItem title="Teaching Assistant - Introduction to Programming (IntProg)"
              firm="Aarhus University"
              period="September 2021 to December 2021"
              text="In the IntProg course, the students learn general programming techniques in Java.
                They then build a simple game to enhance their coding and testing abilities.
                My job was to guide them if they had questions on how to solve tasks and explain
                the material that was being taught by the professor.
                I also spent a lot of them analyzing and assesing their code."
            />
            <ResumeItem title="Team Manager"
              firm="YouSee A/S"
              period="December 2017 to May 2020"
              text="At YouSee, I was a team manager for combined support, billing, retention and
                customer service teams. My primary job was employee development to ensure KPI compliance.
                This was done through regular check-ins and development plans in combination with
                sit-in guidance and feedback from recorded calls."
            />
          </div>

          <div className='column'>
            <h2 className='title has-text-centered mb-6 has-text-info'>Education</h2>
            <ResumeItem title="(In Progress) Master of Science - IT Product Development"
              firm="Aarhus University"
              period="September 2023 to June 2025"
              text="Similar to the bachelor. We dive into more
              advanced topics such as critical design theory and building IOT through P2P.
              As my elective, I have chosen deep learning."
            />
            <ResumeItem title="(Exchange) Master of Science - Computer Science"
              firm="National Univserity of Singapore"
              period="August 2024 to December 2024"
              text="I was on exchange at NUS #1 University in Asia learning about Formal Methods in Software Engineering,
              Algorithmic Mechanism Design, Computer Security and more."
            />
            <ResumeItem title="Bachelor of Science - IT Product Development"
              firm="Aarhus University"
              period="September 2020 to June 2023"
              text="
              IT Product Development focuses on the wide aspect of computer science.
              Our hands on development of software is similar to that of computer science.
              The main difference is that some math courses and compiling have been switched out with design,
              IOT and physical computing. Therefore, we can work in any IT department and help bridge
              the gaps from users to designers to developers."
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function ResumeItem(props) {
  return (
    <div className='container'>
      <p className='title is-5 '>{props.title}</p>
      <p className='subtitle is-6 mb-0 '>{props.firm}</p>
      <p className='subtitle is-7 has-text-grey mb-2'>{props.period}</p>
      <p>{props.text}</p>
      <p className='mb-6 '><strong><i>{props.extra}</i></strong></p>
    </div>
  );
}

function Navigation() {
  const [burgerIsActive, setBurgerIsActive] = useState(false);
  const [hoverProjects, setHoverProjects] = useState(false);
  const [hoverResume, setHoverResume] = useState(false);
  const [hoverContact, setHoverContact] = useState(false);

  return (

    <nav className='navbar gradient is-fixed-top' role='navigation' aria-label='main navigation'>
      <div className='container'>
        <div className='navbar-brand'>
          <a className='navbar-item' href='#profile'>
            <h1 className='title is-5 has-text-white'>MAGNUS FRISK</h1>
          </a>

          <a
            role='button' className='navbar-burger has-text-white' aria-label='menu' aria-expanded='false'
            onClick={e => setBurgerIsActive(!burgerIsActive)}
          >
            <span aria-hidden='true' />
            <span aria-hidden='true' />
            <span aria-hidden='true' />
          </a>
        </div>

        <div className={`navbar-menu ${burgerIsActive ? 'is-active p-0' : ''}`}>
          <div className='navbar-end gradientSmall'>
            <a className={`navbar-item has-text-weight-bold has-text-white ${hoverProjects ? 'has-text-black' : ''}`} onMouseEnter={e => setHoverProjects(true)}
              onMouseLeave={e => setHoverProjects(false)} onClick={e => setBurgerIsActive(false)} href='#projects'>Projects</a>
            <a className={`navbar-item has-text-weight-bold has-text-white ${hoverResume ? 'has-text-black' : ''}`} onMouseEnter={e => setHoverResume(true)}
              onMouseLeave={e => setHoverResume(false)} onClick={e => setBurgerIsActive(false)} href='#resume'>Resume/CV</a>
            <a className={`navbar-item has-text-weight-bold has-text-white ${hoverContact ? 'has-text-black' : ''}`} onMouseEnter={e => setHoverContact(true)}
              onMouseLeave={e => setHoverContact(false)} onClick={e => setBurgerIsActive(false)} href='#contact'>Contact</a>
          </div>
        </div>
      </div>
    </nav>);
}

function Project(props) {
  return (
    <div className='column is-6'>
      <div class="card mb-6">

        <Slider>
          <StyledImages {...props} />
        </Slider>


        <div className="card-content pt-1">
          <div className="media mb-1">
            <div className="media-content noScroll">
              <div className='columns is-8 is-mobile mr-0 ml-0'>
                <div className='column pl-0'>
                  <p className="title is-4 is-size-5-mobile noSroll">{props.title}</p>
                  {
                    props.subIcon ? <p className="subtitle is-size-6 pb-2">
                      <FontAwesomeIcon icon={props.subIcon}></FontAwesomeIcon>
                      <span> {props.project} </span>
                      <FontAwesomeIcon icon={props.subIcon}></FontAwesomeIcon>
                    </p> :
                      <p className="subtitle is-size-6 pb-2">{props.project}</p>
                  }
                </div>
                <div className='column is-3 pr-0'>
                  {props.video ? <p className='title is-5 is-size-6-mobile has-text-right'><a target='blank' href={props.video}>Video</a></p> : ""}
                  {props.report ? <p className='subtitle is-size-6 has-text-right'><a target='blank' href={props.report}>Report</a></p> : ""}
                  {props.paper ? <p className='subtitle is-size-6 has-text-right'><a target='blank' href={props.paper}>Paper</a></p> : ""}
                  {props.website ? <p className='subtitle is-size-6 has-text-right'><a target='blank' href={props.website}>Website</a></p> : ""}
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            {props.text}
            <br></br>
          </div>
        </div>
      </div>
    </div>);
}

function StyledImages(props) {
  const [imagesLoaded, setImagesLoaded] = useState();
  const [images, setImages] = useState();
  const [allLoaded, setAllLoaded] = useState(false);
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const imageCount = props.images.length
  let loadCount = 0;

  const imageLoaded = (e) => {
    loadCount++;
    if (loadCount === imageCount) {
      setAllLoaded(true);
    }
  }

  useEffect(() => {
    setImages(
      <div key={Math.random(100000)} className="card-image">
        <figure className="image is-4by3">
          <img src={props.images[0]} alt="Project" />
        </figure>
      </div>);
  }, []);

  useEffect(() => {
    setImagesLoaded(props.images.map((image) =>
      <div key={Math.random(100000)} className="card-image">
        <figure className="image is-4by3">
          <img src={image} alt="Project" onLoad={imageLoaded()} />
        </figure>
      </div>
    ));
  }, []);




  return (
    <div className='pb-5'>

      <Slider {...settings}>
        {
          allLoaded ? imagesLoaded : images
        }
      </Slider>
    </div>
  )
}




export default App;
